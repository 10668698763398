import React, { useLayoutEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames/bind"
import Img from "gatsby-image"
import SEO from "../../components/seo"

import wordmark_orange from '../../images/brand/wordmark_orange.svg';

import style from "./@lifestyle.module.css"
import Anchors from "./anchors.js"
import Identity from "./identity.js"
import Clients from "./clients.js"
import Team from "./team.js"
import Contact from "./contact.js"
import Location from "./location.js"
import Footer from "./footer.js"
// import { Columns, Baseline } from "../../components/visual-aid.js"
// import client_mindlezz from "../../images/client/client-mindlezz.svg"

import {
  flashImage,
  customHookScrollEvent
  // pageSlowReveal,
  // highlightStoryLink,
  // highlightInterface,
  // customScrollEvent,
  // pullContent
} from "../../components/scroll-effects.js"

const cx = classNames.bind(style)

//


export default function Lifestyle() {

  const data = useStaticQuery(
    graphql`
      query {

        #
        lifestyle_landscape: file(relativePath: { eq: "work/residential/lifestyle_landscape.jpg" }) {
          ...defaultImageTreatment
        }
        #
        lifestyle_portrait: file(relativePath: { eq: "work/residential/lifestyle_portrait.jpg" }) {
          ...defaultImageTreatment
        }

        #
        client_parvinder_01: file(relativePath: { eq: "work/residential/client_parvinder_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_parvinder_02: file(relativePath: { eq: "work/residential/client_parvinder_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_parvinder_03: file(relativePath: { eq: "work/residential/client_parvinder_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_parvinder_05: file(relativePath: { eq: "work/residential/client_parvinder_05.jpg" }) {
          ...defaultImageTreatment
        }
        #

        #
        client_rajeev_01: file(relativePath: { eq: "work/residential/client_rajeev_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_rajeev_02: file(relativePath: { eq: "work/residential/client_rajeev_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_rajeev_03: file(relativePath: { eq: "work/residential/client_rajeev_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_rajeev_04: file(relativePath: { eq: "work/residential/client_rajeev_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_rajeev_05: file(relativePath: { eq: "work/residential/client_rajeev_05.jpg" }) {
          ...defaultImageTreatment
        }
        #

        #
        client_rajesh_01: file(relativePath: { eq: "work/residential/client_rajesh_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_rajesh_02: file(relativePath: { eq: "work/residential/client_rajesh_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_rajesh_03: file(relativePath: { eq: "work/residential/client_rajesh_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_rajesh_05: file(relativePath: { eq: "work/residential/client_rajesh_05.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_rajesh_07: file(relativePath: { eq: "work/residential/client_rajesh_07.jpg" }) {
          ...defaultImageTreatment
        }
        #

        #
        client_ridhu_01: file(relativePath: { eq: "work/residential/client_ridhu_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_ridhu_02: file(relativePath: { eq: "work/residential/client_ridhu_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_ridhu_03: file(relativePath: { eq: "work/residential/client_ridhu_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_ridhu_04: file(relativePath: { eq: "work/residential/client_ridhu_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_ridhu_05: file(relativePath: { eq: "work/residential/client_ridhu_05.jpg" }) {
          ...defaultImageTreatment
        }
        #


        #
        client_tanul_01: file(relativePath: { eq: "work/residential/client_tanul_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_tanul_02: file(relativePath: { eq: "work/residential/client_tanul_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_tanul_03: file(relativePath: { eq: "work/residential/client_tanul_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_tanul_04: file(relativePath: { eq: "work/residential/client_tanul_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_tanul_05: file(relativePath: { eq: "work/residential/client_tanul_05.jpg" }) {
          ...defaultImageTreatment
        }
        #
      }
    `
  )

  useLayoutEffect(() => {
    flashImage("#client_parvinder_01")
    flashImage("#client_parvinder_02")
    flashImage("#client_parvinder_03")
    flashImage("#client_parvinder_05")
    // //
    flashImage("#client_rajeev_01")
    flashImage("#client_rajeev_02")
    flashImage("#client_rajeev_03")
    flashImage("#client_rajeev_04")
    flashImage("#client_rajeev_05")
    // //
    flashImage("#client_rajesh_01")
    flashImage("#client_rajesh_02")
    flashImage("#client_rajesh_03")
    flashImage("#client_rajesh_05")
    flashImage("#client_rajesh_07")
    // //
    flashImage("#client_ridhu_01")
    flashImage("#client_ridhu_02")
    flashImage("#client_ridhu_03")
    flashImage("#client_ridhu_04")
    flashImage("#client_ridhu_05")
    // //
    flashImage("#client_tanul_01")
    flashImage("#client_tanul_02")
    flashImage("#client_tanul_03")
    flashImage("#client_tanul_04")
    flashImage("#client_tanul_05")
    //
    //
    customHookScrollEvent("#tagline", "#tagline", "tagline_expanded", .9)
    customHookScrollEvent("#taglineTop", "#taglineTop", "taglineTop_expanded", .5)
  }, [])

  return (
    <>
      {/* <Columns />
      <Baseline /> */}

      <SEO
        title="Spaces For Life"
        keywords={[`UX`, `UX Strategy`, `Bangalore`, `UX Design`, `Consultant`]}
      />
      <div className={style.page}>
        <main className={style.main}>
          <div className={style.aboveFold}>
            <div className={style.topLine}></div>
            <div className={style.topImage}>
              <div className={cx(style.topImageAsset)}>
                <div className={style.matte}></div>
                <Img
                  className={cx(style.image, style.bannerLandscape)}
                  fluid={data.lifestyle_landscape.childImageSharp.fluid}
                  alt="lifestyle_landscape.jpg"
                />
                <Img
                  className={cx(style.image, style.bannerPortrait)}
                  fluid={data.lifestyle_portrait.childImageSharp.fluid}
                  alt="lifestyle_portrait.jpg"
                />
              </div>

            </div>
            <div className={style.topInfo}>
              <img className={style.wordmark} src={wordmark_orange} alt="wordmark.svg" />
              <div id="taglineTop" className={style.taglineTop}>
                spaces for growth
              </div>
            </div>
          </div>

          <div id="about-work" className={style.aboutWork}>
            <div className={style.header}>Residential Interiors</div>
            <div className={style.aboutWorkText}>
              Designing a house interior needs completely distinct approach compared to a public building. We firmly believe that your home should be the reflections of your thoughts and your personality. It must provide the desired comfort and relaxation to your Mind, Body and Soul. SAMYAK as the name suggests acts as a catalyst that helps in giving visual presence of your inner self. We try to fulfil all your dreams within your budget and time. Also, the concept of designing a house interior for every person is unique so every home should be unique in its own way. Our design enhances the essence of your thoughts and creates a miracle for you. Our methodology includes intense interactions with the end-user and planning details up to the microscopic levels. We not only design a house but we build a relationship with our creations.
            </div>
          </div>
          <div id="work" className={style.work}>
            <div className={style.header}>Work</div>

            {/* Rajesh ****************************** */}
            <div className={style.project}>
              <div className={style.projectDescription}>
                <div className={style.projectDescriptionHighlight}>
                  Colourful liveness
                </div>
                <div className={style.projectDescriptionText}>
                  <p>
                    Doctor Dahiya's family house is on 2nd floor with 3500 sqft carpet area. The centre of attraction is a small swimming pool with bar area.  Flooring is Italian marble predominantly throughout the house, accented by carpet rugs in Drawing room & Master bed room. Kitchen is modular with a pleasant and contemporary feel. Colour scheme in drawing cum dining hall is fresh, modern and inviting. Kids room design fascinates both kids of Dahiya couple. Beds are in shape of their favourite car models. Wall backdrop has cool feeling of courtyard side with tree goes straight to the sky (ceiling). Kids have been provided with their separate study, toys storages and wardrobes. Master bed room designed with posh luxury finishes - Veneer, PU polishes & Italian marble has accented the overall look & feel. Master toilet has its own luxury with Jacuzzi settings. A small temple placed at the end of main lobby has been designed as per Vaastu.
                  </p>
                  <p>
                    The door of temple is made in pure wood with square see-through cut-outs. These cut-outs contain small bells, which give a divine feel when you cross this area even outside of temple.
                    Terrace of second floor is designed as landscape court with grass, reflective tiles and pergolas above sit-outs. This house has realized the life-long dream of Dr. Dahiya’s family.
                  </p>
                </div>
                <div className={style.projectData}>
                  <div className={style.projectName}>Dr. Rajesh & Dr. Poonam Dahiya</div>
                  <div className={style.projectLocation}>Narela, New Delhi</div>
                  <div className={style.projectIndustry}>Residential</div>
                  <div className={style.projectCompletion}>2020</div>
                  <div className={style.projectSize}>3500</div>
                </div>
              </div>
              <div className={style.projectImages}>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_rajesh_01" className={cx(style.image)}>
                    <Img fluid={data.client_rajesh_01.childImageSharp.fluid}
                      alt="client_rajesh_01.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_rajesh_02" className={cx(style.image)}>
                    <Img fluid={data.client_rajesh_02.childImageSharp.fluid}
                      alt="client_rajesh_02.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_rajesh_03" className={cx(style.image)}>
                    <Img fluid={data.client_rajesh_03.childImageSharp.fluid}
                      alt="client_rajesh_03.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_rajesh_05" className={cx(style.image)}>
                    <Img fluid={data.client_rajesh_05.childImageSharp.fluid}
                      alt="client_rajesh_05.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_rajesh_07" className={cx(style.image)}>
                    <Img fluid={data.client_rajesh_07.childImageSharp.fluid}
                      alt="client_rajesh_07.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Rajesh ****************************** */}

            {/* Ridhu ****************************** */}
            <div className={style.project}>
              <div className={style.projectDescription}>
                <div className={style.projectDescriptionHighlight}>
                  Contemporary minimalism
                </div>
                <div className={style.projectDescriptionText}>
                  <p>
                    This apartment interiors were done in ultra-modern way. The concept given by client was minimalistic rustic. The major tone selected was Grey & White along with wooden accent texture. The design involved neat straight lines & grooves throughout. At some places White upon White concept is used, creating White balanced grooves on walls and ceilings of the bedrooms. In bedrooms extra storage cabinets were provided as per clients specific requirements. </p>
                  <p>
                    The dressing mirror was provided in each of the bedrooms in different ways. A bar counter area was created in the middle lobby space. The dining areas was provided with large window cut-out with ledge seating. It has visually connected the internal lobby with dining space. It also had enlarged the space in feeling. Drawing room is accented with white brick texture and new loose furniture. Many existing furniture was used like – dining table, beds, couches and crockery table. The design was done in a manner to adjust the look and feel of old furniture with new ambience.
                  </p>
                </div>
                <div className={style.projectData}>
                  <div className={style.projectName}>Miss Ridhu Bhatia</div>
                  <div className={style.projectLocation}>M3M Golf Course, Gurgaon</div>
                  <div className={style.projectIndustry}>Residential</div>
                  <div className={style.projectCompletion}>2020</div>
                  <div className={style.projectSize}>2500</div>
                </div>
              </div>
              <div className={style.projectImages}>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_ridhu_01" className={cx(style.image)}>
                    <Img
                      fluid={data.client_ridhu_01.childImageSharp.fluid}
                      alt="client_ridhu_01.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_ridhu_02" className={cx(style.image)}>
                    <Img
                      fluid={data.client_ridhu_02.childImageSharp.fluid}
                      alt="client_ridhu_02.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_ridhu_03" className={cx(style.image)}>
                    <Img
                      fluid={data.client_ridhu_03.childImageSharp.fluid}
                      alt="client_ridhu_03.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_ridhu_04" className={cx(style.image)}>
                    <Img
                      fluid={data.client_ridhu_04.childImageSharp.fluid}
                      alt="client_ridhu_04.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_ridhu_05" className={cx(style.image)}>
                    <Img
                      fluid={data.client_ridhu_05.childImageSharp.fluid}
                      alt="client_ridhu_05.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Ridhu ****************************** */}

            {/* Tanul ****************************** */}
            <div className={style.project}>
              <div className={style.projectDescription}>
                <div className={style.projectDescriptionHighlight}>
                  European Accents
                </div>
                <div className={style.projectDescriptionText}>
                  <p>
                    Our client Mr. Tanul Singhal a retired Major from Army is very particular about his requirements. His brief specified that he was looking for house interiors with European theme. The design was done with pure contemporary approach. Emphasis was made to create bold elements. Use of straight line and ordered elements can be seen everywhere. The bar counter has interesting features of MS black solid pipe accented with wooden and glass racks inserted. The counter has reconstituted stone top as extension of wooden ledge right from the he entry door. Similar stone is used for kitchen counter. Modular kitchen has been supplied by Spacewood. Use of English colours are dominating as strong impressive elements. .
                  </p>
                  <p>
                    The different bedrooms are designed in different ways according to respecrive users. All the 3 toliets attached with the bedrooms are also designed in different ways. The interface between drawing and dining area contains an aquarium. The turkish blue colour of acrylic shutters are adding purity in the dining as well as bar areas. A backlit corian temple has found its place at one side of dining area. The balconies attached with each of the bedrooms are designed in different themes. Overall there is a scent of freshness throughout the interior environment.
                  </p>
                </div>
                <div className={style.projectData}>
                  <div className={style.projectName}>Mr. Tanul Singhal</div>
                  <div className={style.projectLocation}>Noida, UP, India.</div>
                  <div className={style.projectIndustry}>Residential</div>
                  <div className={style.projectCompletion}>2020</div>
                  <div className={style.projectSize}>2050</div>
                </div>
              </div>
              <div className={style.projectImages}>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_tanul_01" className={cx(style.image)}>
                    <Img fluid={data.client_tanul_01.childImageSharp.fluid}
                      alt="client_tanul_01.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_tanul_02" className={cx(style.image)}>
                    <Img fluid={data.client_tanul_02.childImageSharp.fluid}
                      alt="client_tanul_02.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_tanul_03" className={cx(style.image)}>
                    <Img fluid={data.client_tanul_03.childImageSharp.fluid}
                      alt="client_tanul_03.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_tanul_04" className={cx(style.image)}>
                    <Img fluid={data.client_tanul_04.childImageSharp.fluid}
                      alt="client_tanul_04.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_tanul_05" className={cx(style.image)}>
                    <Img fluid={data.client_tanul_05.childImageSharp.fluid}
                      alt="client_tanul_05.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Tanul ****************************** */}

            {/* Rajeev ****************************** */}
            <div className={style.project}>
              <div className={style.projectDescription}>
                <div className={style.projectDescriptionHighlight}>
                  True Homeliness
                </div>
                <div className={style.projectDescriptionText}>
                  <p>
                    Manas apartment was a 3 BHK apartment close to Mayur Vihar, phase-1, metro station. The design brief given by client was a long wish-list. They were looking for a contemporary space which must carry the traditional elements of culture as well. The first challenge in design was – how to maximize the space potential with minimum changes proposed. It was done wisely by removing the divider wall between existing kitchen and drawing hall and shifting the kitchen next to the guest room. It increased the uninterrupted space in centre. The newly created central hall is designed as drawing, living and dining area.
                  </p>
                  <p>
                    The centre of attraction in this hall is a traditional jhoola (made purely in wood by carpenters in Ahmedabad, Gujarat) placed near fire-place and library. This whole area is created with wooden touch to embed the feel of luxury with relax. Fire place backdrop is rough slate stone texture with fire place made in marble stone.
                    Floor is done Italian marble in central area while wooden flooring is done in bedrooms. Kitchen is a modular one from a reputed brand. Another interesting item to notice is temple niche. The outer framework has been carved out of pure wooden and represents an excellent symbol of South-Indian traditional temple.
                    Overall it has been a true – ‘Home Sweet Home’ experience for the Murugai family.
                  </p>
                </div>
                <div className={style.projectData}>
                  <div className={style.projectName}>Mr. Rajeev Murgai</div>
                  <div className={style.projectLocation}>Mayur Vihar-1, New Delhi</div>
                  <div className={style.projectIndustry}>Residential</div>
                  <div className={style.projectCompletion}>2015</div>
                  <div className={style.projectSize}>1500</div>
                </div>
              </div>
              <div className={style.projectImages}>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_rajeev_04" className={cx(style.image)}>
                    <Img
                      fluid={data.client_rajeev_04.childImageSharp.fluid}
                      alt="client_rajeev_04.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_rajeev_02" className={cx(style.image)}>
                    <Img
                      fluid={data.client_rajeev_02.childImageSharp.fluid}
                      alt="client_rajeev_02.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_rajeev_03" className={cx(style.image)}>
                    <Img
                      fluid={data.client_rajeev_03.childImageSharp.fluid}
                      alt="client_rajeev_03.jpg"
                    />
                  </div>

                </div>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_rajeev_01" className={cx(style.image)}>
                    <Img fluid={data.client_rajeev_01.childImageSharp.fluid}
                      alt="client_rajeev_01.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_rajeev_05" className={cx(style.image)}>
                    <Img fluid={data.client_rajeev_05.childImageSharp.fluid}
                      alt="client_rajeev_05.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Rajeev ****************************** */}

            {/* Parvinder ****************************** */}
            <div className={style.project}>
              <div className={style.projectDescription}>
                <div className={style.projectDescriptionHighlight}>
                  Eloquently Modern
                </div>
                <div className={style.projectDescriptionText}>
                  <p>
                    The client brief was to remodel the interiors of all key areas. The design was proposed to completely transform the old look to modern and contemporary. We retained the existing marble floor. Apart from flooring everything in the room was changed. False ceiling re-designed with suitable LED cove lights and new recessed lights. The main hall is divided in 3 parts. First part as formal sofa seating, second part as informal seating and third part as dining area. The segregation of dining area is done with a high bar counter made in white corian top, orange lacquered glass and walnut veneer shutters.  <br />
                  </p>
                  <p>
                    The existing dining table was re-polished in new colour and the chairs modified to new look with cream leather upholstery. The dining backdrop wall is designed with universal groove flow pattern in pop. The bar counter is provided with pendent circular lights while drawing hall is proposed with square sleek chandelier (in contrast to the old one). The existing sofas in the drawing room were transformed to new – Avatar. The final outcome of the product was unbelievable for client. It was almost a new furniture for them. The central coffee table is also a very sleek and new tapered design in polish finish. Old wall paintings were replaced with new ones in selective manner. The large wall is painted in light grey accent colour while other walls and ceiling maintained white. It was a great assignment which completed successfully and filled our client with joy & energy.
                  </p>
                </div>
                <div className={style.projectData}>
                  <div className={style.projectName}>Mr. Parvinder Khurana</div>
                  <div className={style.projectLocation}>GK-1, New Delhi</div>
                  <div className={style.projectIndustry}>Residential</div>
                  <div className={style.projectCompletion}>2015</div>
                  <div className={style.projectSize}>1200</div>
                </div>
              </div>
              <div className={style.projectImages}>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_parvinder_01" className={cx(style.image)}>
                    <Img fluid={data.client_parvinder_01.childImageSharp.fluid}
                      alt="client_parvinder_01.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_parvinder_02" className={cx(style.image)}>
                    <Img fluid={data.client_parvinder_02.childImageSharp.fluid}
                      alt="client_parvinder_02.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_parvinder_03" className={cx(style.image)}>
                    <Img fluid={data.client_parvinder_03.childImageSharp.fluid}
                      alt="client_parvinder_03.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_parvinder_05" className={cx(style.image)}>
                    <Img fluid={data.client_parvinder_05.childImageSharp.fluid}
                      alt="client_parvinder_05.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Parvinder ****************************** */}

          </div>

          <div id="team" >
            < Team />
          </div>

          <div id="clients" >
            < Clients />
          </div>



          <div id="location" >
            < Location />
          </div>

          {/* <div id="contact" > */}
          < Footer />
          {/* </div> */}
          <div id="contact" >
            < Contact />
          </div>

          < Identity />

          < Anchors />

        </main>
      </div>

    </>
  )
}
